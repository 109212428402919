import request from '@/utils/request'

export function fetchLatestTask(query) {
  return request({
    url: '/v1/gpt-tasks/latest',
    method: 'get',
    params: query,
  })
}

export function fetchTaskDates(query) {
  return request({
    url: '/v1/gpt-tasks/dates',
    method: 'get',
    params: query,
  })
}

export function fetchOneTask(id) {
  return request({
    url: '/v1/gpt-tasks/' + id,
    method: 'get',
  })
}

export function fetchTasks(query) {
  return request({
    url: '/v1/gpt-tasks',
    method: 'get',
    params: query,
  })
}

export function createTask(data) {
  return request({
    url: '/v1/gpt-tasks',
    method: 'post',
    data
  })
}

export function restartTask(id) {
  return request({
    url: '/v1/gpt-tasks/' + id + '/restart',
    method: 'post',
  })
}

export function abortTask(id) {
  return request({
    url: '/v1/gpt-tasks/' + id + '/abort',
    method: 'post',
  })
}

export function deleteTask(id) {
  return request({
    url: '/v1/gpt-tasks/' + id,
    method: 'delete',
  })
}

export function fetchImages(query) {
  return request({
    url: '/v1/gpt-images',
    method: 'get',
    params: query
  })
}

export function blockImage(id) {
  return request({
    url: '/v1/gpt-images/block/' + id,
    method: 'post',
  })
}

export function fetchOptions(query) {
  return request({
    url: '/v1/gpt-tasks/options',
    method: 'get',
    params: query
  })
}

export function fetchTargets(query) {
  return request({
    url: '/v1/gpt-targets',
    method: 'get',
    params: query
  })
}